<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-select clearable v-model="options.params.status" placeholder="状态筛选">
                        <el-option :key="1" :value="1" label="待处理"></el-option>
                        <el-option :key="2" :value="2" label="已完成"></el-option>
                        <el-option :key="3" :value="3" label="已拒绝"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="options.params.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
            <template slot="actions" slot-scope="scope">
                <template v-if="$perm('league_Withdrawal_edit') && scope.row.status === 0">
                    <el-button type="text" @click="doCalc(scope.row.id)" icon="el-icon-check"
                               class="color-success"> 完成
                    </el-button>
                    <el-button type="text" @click="doReject(scope.row.id)" icon="el-icon-close"
                               class="color-danger"> 拒绝
                    </el-button>
                </template>
            </template>
        </crud>

    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: false,
                editBtn: false,
                delBtn: true,
                pager: true,
                actionWidth: 200,
                addUrl: 'league.Withdrawal.add',
                addPerm: 'league_Withdrawal_add',
                editUrl: 'league.Withdrawal.edit',
                editPerm: 'league_Withdrawal_edit',
                delUrl: 'league.Withdrawal.delete',
                delPerm: 'league_Withdrawal_delete',
                listUrl: 'league.Withdrawal.page',
                formLabelWidth: '100px',
                params: {
                    status: null,
                    date: null
                },
                columns: [
                    {
                        prop: "member_id", label: "提现用户", formatter: row => {
                            return row.member ? row.member.nickname : ''
                        }
                    },
                    {prop: "mobile", label: "联系电话", required: true},
                    {prop: "amount", label: "提现金额",},
                    {
                        prop: "qrcode", label: "收款码", width: 72,
                        html: ({row}) => {
                            return row.qrcode ? `<a href="${row.qrcode}" target="_blank"><img class="list-user-avatar" src="${row.qrcode}" alt="加载失败"/></a>` : null
                        }
                    },
                    {prop: "remark", label: "备注",},
                    {
                        prop: "admin_id", label: "处理人", formatter: row => {
                            return row.admin ? row.admin.nickname : null
                        }
                    },
                    {prop: "calc_time", label: "处理时间",},
                    {prop: "result", label: "处理结果",},
                    {
                        prop: "status", label: "处理状态", formatter: row => {
                            return row.status === 0 ? '待处理' : row.status === 1 ? '已完成' : '已拒绝'
                        }
                    },
                    {prop: "create_time", label: "申请时间",},
                ],
            }
        }
    },
    methods: {
        doCalc: function (id) {
            this.$helper._post(this, 'league.Withdrawal.calc', {id: id, status: 1, result: '已提现'}, resp => {
                this.$message.success(resp.message)
                this.$refs.crud.getLists()
            })
        },
        doReject: function (id) {
            this.$prompt('请输入拒绝原因', '提示').then(({value}) => {
                if (!value || value.trim().length <= 0) return this.$message.warning("请输入拒绝原因~")
                this.$helper._post(this, 'league.Withdrawal.calc', {id: id, status: 2, result: value}, resp => {
                    this.$message.success(resp.message)
                    this.$refs.crud.getLists()
                })
            }).catch(() => {
            });
        }
    }
}
</script>

<style scoped>

</style>
